// MODAL
// ========================================================================
.uk-modal {
    padding: 20px;
    font-family: $global-font-family;
    text-align: center;
    @media (min-width: $breakpoint-medium-light) {
        padding: $padding-padding*2;
    }
    .uk-grid {
        margin-left: 0;
    }
    p {
        font-size: 1.4em;
        text-align: center;
    }
    .uk-close {
        color: $global-color-dark-gray;
    }
    .uk-link {
        color: $global-color-green-02;
    }
    .container-modal-buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        button {
            &::after {
                content: none;
            }
            &.uk-modal-close-default {
                position: relative;
                top: unset;
                right: unset;
                padding: ($padding-padding/4) ($padding-padding*1.5);
                font-size: 1em;
            }
        }
    }
}

button.uk-modal-close-default.uk-close-large.uk-icon.uk-close {
    width: 3%;
    height: 3%;
    color: $global-color-white;
    svg {
        width: 90px;
        height: 90px;
    }
}