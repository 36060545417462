//
// Component: Détail Produits
//
// ========================================================================
.section-slider-produits {
    margin: $margin-margin*2 0;
    .container-slider-details {
        margin-left: 0;
        display: block;
        @media (min-width: $breakpoint-medium-light) {
            display: flex;
        }
        .slider {
            padding-left: 0;
            position: relative;
            width: 100%;
            @media (min-width: $breakpoint-medium-light) {
                width: 40%;
            }
            .uk-slideshow-items {
                li {
                    border: 1px solid #e4e4e4;
                    .discount {
                        position: absolute;
                        right: 0;
                        top: 0;
                        display: block;
                        background: $global-color-green-02;
                        color: white;
                        font-weight: 500;
                        z-index: 1;
                        font-size: 1.3em;
                        padding: 0 10px;
                    }
                    figure {
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .uk-slider-container {
                margin: $margin-margin 0;
                position: relative;
                @media (min-width: $breakpoint-medium-light) {
                    display: block;
                }
                .uk-thumbnav {
                    width: 100%;
                    margin: 0 0 0 19px;
                    justify-content: center;
                    height: 55px;
                    overflow: hidden;
                    @media (min-width: $breakpoint-small) {
                        height: 155px;
                        margin-left: 60px;
                    }
                    @media (min-width: $breakpoint-medium-light) {
                        height: 140px;
                        margin-left: 40px;
                    }
                    li {
                        width: auto;
                        margin: 0 2% 10px 2%;
                        overflow: hidden;
                        @media (min-width: $breakpoint-small) {
                            //width: 25%;
                        }
                        @media (min-width: $breakpoint-medium-light) {
                            //width: 35%;
                            margin: 0 2%;
                            padding: 0;
                        }
                        @media (min-width: $breakpoint-xlarge) {
                            width: auto;
                        }
                        &:hover {
                            a {
                                border-bottom: 0;
                                &::after {
                                    background-color: transparent;
                                }
                            }
                        }
                        a {
                            border-bottom: 0;
                            figure {
                                width: 50px;
                                height: 50px;
                                overflow: hidden;
                                padding: 0;
                                margin: 0;
                                @media (min-width: $breakpoint-small) {
                                    width: 140px;
                                    height: 140px;
                                }
                            }
                            img {
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    >*>*::after {
                        background: rgba($global-color, 0.4);
                        transition: all .3s ease-in-out;
                    }
                    >.uk-active>*::after {
                        background-color: transparent;
                    }
                }
                .uk-position-small[class*='uk-position-center-left'],
                .uk-position-small[class*='uk-position-center-right'] {
                    transform: none;
                    padding: 0;
                    margin: 0;
                    top: 25%;
                    border: none;
                    transition: .2s all ease-in-out;
                    @media (min-width: $breakpoint-small) {
                        top: 0;
                        height: 140px;
                        background: $global-color-green-02;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    svg {
                        color: white;
                    }
                    &:hover {
                        border: none;
                        background: $global-color-orange;
                    }
                }
            }
            .uk-dotnav {
                display: flex;
                margin: 20px 0 0 0;
                align-items: center;
                @media (min-width: $breakpoint-small) {
                    display: none;
                }
                li {
                    a {
                        background: $global-color;
                        border: 1px solid $global-color-white;
                        opacity: 1;
                        box-shadow: none;
                    }
                }
                .uk-active {
                    a {
                        background: $global-color;
                        border: 1px solid $global-color-white;
                        opacity: 1;
                        box-shadow: none;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
        .details {
            width: 100%;
            @media (min-width: $breakpoint-medium-light) {
                padding-left: 40px;
                width: 60%;
            }
            .content {
                h1 {
                    text-transform: none;
                    margin: 0 0 $margin-margin/2 0;
                    color: $global-color-green-01;
                    padding: 0;
                    max-width: 100%;
                    text-align: left;
                    font-size: 1.5em;
                    font-weight: 500;
                }
                .ref {
                    color: $global-color-gray-soft;
                    padding: 0 0 $padding-padding/2 0;
                    font-style: italic;
                    font-size: .8em;
                    display: block;
                    border-bottom: 1px solid $global-color-gray-soft;
                }
                .container-prices {
                    display: flex;
                    align-items: center;
                    font-size: 1.8em;
                    margin-top: 10px;
                    .price {
                        font-weight: bold;
                        color: $global-color-green-02;
                    }
                    .old-price {
                        color: $global-color-gray-soft;
                        font-size: 1rem;
                        text-decoration: line-through;
                        margin-left: 15px;
                    }
                }
                .container-description {
                    padding: 10px 20px 20px 20px;
                    background: #F8F8F8;
                    margin-top: 20px;
                    .title-description {
                        color: $global-color-green-01;
                        font-weight: 500;
                        padding: 0;
                        margin: 0;
                    }
                    .description {
                        padding: 10px 20px;
                        background: $global-color-white;
                        border: 1px solid $global-color-gray-soft;
                        ul {
                            list-style: none;
                            padding-left: 15px;
                            margin: 0;
                            li {
                                &:before {
                                    content: "-";
                                    margin-right: 5px;
                                    color: $global-color;
                                }
                            }
                        }
                    }
                }
                .container-detail-livraison {
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 4em;
                        color: $global-color-green-01;
                        font-weight: 500;
                    }
                    p {
                        font-weight: 500;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.add-card-button {
    width: 100%;
    border: none;
    background: $global-color-green-02;
    color: white;
    text-align: center;
    padding: $padding-padding/2 0;
    margin: $margin-margin 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 500;
    cursor: pointer;
    transition: .2s all ease-in-out;
    span {
        font-size: 1.8em;
        margin-right: $margin-margin/2;
    }
    &:hover {
        background: $global-color-orange;
    }
}

.section-background {
    &.section-background-gray {
        padding: 0;
        background: #f8f8f8;
        .container-h2 {
            h2 {
                color: $global-color-green-01;
            }
            span {
                color: $global-color-green-02;
            }
        }
    }
}