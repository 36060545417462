//
// Component: Contact
//
// ========================================================================
.section-map {
    margin: $margin-margin*2 auto;
    .container-map {
        display: block;
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: center;
        }
        div {
            @media (min-width: $breakpoint-small) {
                &:first-child {
                    margin-right: $margin-margin;
                }
                &:last-child {
                    margin-left: $margin-margin;
                }
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                @media (min-width: $breakpoint-small) {
                    width: auto;
                }
                @media (min-width: $breakpoint-medium-light) {
                    width: 45%;
                }
                span {
                    font-size: 2em;
                    margin-right: 10px;
                }
            }
        }
    }
}

.section-contact-form {
    .contact-form {
        .content {
            padding: $padding-padding/2;
            background: #f8f8f8;
            margin: $margin-margin*2 0;
            @media (min-width: $breakpoint-small) {
                padding: $padding-padding*2;
                margin: $margin-margin*2 0 $margin-margin*4 0;
            }
            h3 {
                font-style: italic;
            }
        }
    }
}