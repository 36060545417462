//
// Component: Nav bar
//
// Nav bar
// ========================================================================
.desktop {
    .uk-navbar-toggle {
        color: $global-color;
        display: none;
    }
}

.mobile {
    .uk-navbar-toggle {
        color: $global-color;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.container-navigation {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    height: 100%;
    z-index: 10;
    right: -100%;
    transition: all .3s ease-in-out;
    &.open {
        right: 0;
    }
    @media (min-width: $breakpoint-small) {
        z-index: 0;
        height: inherit;
        position: relative;
        width: 100%;
        padding: 0;
        right: inherit;
    }
    .uk-navbar {
        display: flex;
        align-items: center;
        height: 100%;
        overflow-y: scroll;
        @media (min-width: $breakpoint-small) {
            align-items: inherit;
            height: inherit;
            overflow-y: inherit;
        }
        .uk-navbar-nav {
            margin: 0 auto 100px auto;
            padding: 250px 0 150px 0;
            align-items: center;
            display: block;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            @media (min-width: $breakpoint-small) {
                display: flex;
                justify-content: space-between;
                width: 1200px;
                height: inherit;
                overflow-y: inherit;
                padding: 0;
                margin: 0 auto;
            }
            @media (min-width: $breakpoint-large) {
                width: 1300px;
            }
            li {
                position: relative;
                a {
                    color: $global-color;
                    text-transform: none;
                    margin: 0;
                    padding: 0 40px;
                    transition: all .2s ease-in-out;
                    font-size: 1.1em;
                    text-transform: uppercase;
                    min-height: 50px;
                    @media (min-width: $breakpoint-small) {
                        font-size: 1em;
                        text-transform: inherit;
                    }
                    &:hover {
                        background: $global-color-green-03;
                        color: white;
                    }
                    &.uk-open {
                        background: $global-color-green-03;
                        color: white;
                    }
                }
                .uk-navbar-dropdown {
                    padding: 0 5%;
                    box-shadow: none;
                    width: 100%;
                    top: 0!important;
                    position: relative;
                    @media (min-width: $breakpoint-small) {
                        left: 0;
                        background: #f8f8f8;
                        top: 30px!important;
                        padding: 50px;
                        position: absolute;
                        border-bottom: 8px solid $global-color-green-03;
                    }
                    .uk-navbar-dropdown-nav {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        grid-gap: 0;
                        margin-bottom: 150px;
                        @media (min-width: $breakpoint-small) {
                            grid-gap: 10px;
                            grid-template-columns: repeat(3, 1fr);
                            margin-bottom: 0;
                        }
                        li {
                            a {
                                padding: 0;
                                font-style: italic;
                                color: $global-color-dark-gray;
                                border-bottom: 2px solid transparent;
                                display: table;
                                transition: all .2s ease-in-out;
                                text-transform: none;
                                min-height: inherit;
                                &:before {
                                    content: "\e900";
                                    font-family: 'icomoon';
                                    opacity: 0;
                                    vertical-align: middle;
                                    margin-right: 10px;
                                    transition: all .2s ease-in-out;
                                }
                                &:hover {
                                    background: none;
                                    border-bottom: 2px solid $global-color-green-03;
                                    &:before {
                                        opacity: 1;
                                        margin-right: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .separator {
                display: none;
                width: 1px;
                height: 30px;
                background: $global-color-gray-soft;
                @media (min-width: $breakpoint-small) {
                    display: block;
                }
            }
        }
    }
}

#container-nav-mobile {
    display: block;
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 5px;
    width: 100%;
    z-index: 1000;
    background: $global-color-green-01;
    @media (min-width: $breakpoint-small) {
        display: none;
    }
    .close-menu-button {
        position: absolute;
        top: 0;
        right: 0;
    }
    .list-items-nav-mobile {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        li {
            width: 20%;
            a {
                display: block;
                border-bottom: none;
                &:hover {
                    border-bottom: none;
                }
                &.item-nav-mobile {
                    color: $global-color-white;
                    font-size: 2em;
                    text-align: center;
                    font-weight: bold;
                    &.active {
                        color: $global-color-white;
                        &:before {
                            color: $global-color-white;
                        }
                    }
                }
            }
        }
    }
    /* Icon 2 */
    #nav-icon2 {
        width: 40px;
        height: 35px;
        position: relative;
        margin: 0 auto;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
    }
    #nav-icon2 span {
        display: block;
        position: absolute;
        height: 4px;
        width: 50%;
        background: white;
        opacity: 1;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }
    #nav-icon2 span:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
    }
    #nav-icon2 span:nth-child(odd) {
        left: 0px;
        border-radius: 9px 0 0 9px;
    }
    #nav-icon2 span:nth-child(1),
    #nav-icon2 span:nth-child(2) {
        top: 0px;
    }
    #nav-icon2 span:nth-child(3),
    #nav-icon2 span:nth-child(4) {
        top: 12px;
    }
    #nav-icon2 span:nth-child(5),
    #nav-icon2 span:nth-child(6) {
        top: 24px;
    }
    #nav-icon2.open span:nth-child(1),
    #nav-icon2.open span:nth-child(6) {
        transform: rotate(45deg);
    }
    #nav-icon2.open span:nth-child(2),
    #nav-icon2.open span:nth-child(5) {
        transform: rotate(-45deg);
    }
    #nav-icon2.open span:nth-child(1) {
        left: 3px;
        top: 11px;
    }
    #nav-icon2.open span:nth-child(2) {
        left: calc(50% - 5px);
        top: 11px;
    }
    #nav-icon2.open span:nth-child(3) {
        left: -50%;
        opacity: 0;
    }
    #nav-icon2.open span:nth-child(4) {
        left: 100%;
        opacity: 0;
    }
    #nav-icon2.open span:nth-child(5) {
        left: 3px;
        top: 23px;
    }
    #nav-icon2.open span:nth-child(6) {
        left: calc(50% - 5px);
        top: 23px;
    }
}