//
// Component: Home
//
// Home
// ========================================================================
section {
    position: relative;
}

.section-slideshow {
    .uk-position-center {
        max-width: 90%;
        @media (min-width: $breakpoint-small) {
            max-width: 60%;
        }
        h1 {
            font-family: $global-heading-font-family;
            color: white;
            font-style: italic;
            font-size: 2em;
            text-shadow: 0 4px 3px rgba(0, 0, 0, .4), 0 8px 13px rgba(0, 0, 0, .1), 0 18px 23px rgba(0, 0, 0, .1);
            @media (min-width: $breakpoint-small) {
                font-size: 3.5em;
            }
        }
    }
}

.section-navigation-facettes {
    margin: $margin-margin*2 0;
    .uk-container {
        text-align: center;
    }
    .container-nav-facette {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
        margin: $margin-margin*2 0;
        @media (min-width: $breakpoint-small) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: $breakpoint-medium) {
            grid-template-columns: repeat(3, 1fr);
        }
        .container-img {
            overflow: hidden;
            height: 245px;
            @media (min-width: $breakpoint-small) {
                height: 250px;
            }
            @media (min-width: $breakpoint-medium) {
                height: 520px;
            }
            a {
                display: block;
                position: relative;
                color: white;
                font-size: 1.8em;
                transition: all .2s ease-in-out;
                .uk-position-center {
                    display: flex;
                    align-items: flex-end;
                    z-index: 1;
                    span {
                        margin-left: 10px;
                        font-weight: normal;
                        font-size: .8em;
                    }
                }
                p {
                    margin: 0;
                    padding: 0;
                    z-index: 1;
                    line-height: 1;
                    text-align: left;
                    font-weight: 200;
                    font-size: .9em;
                    text-shadow: 0 4px 3px rgba(0, 0, 0, .4), 0 8px 13px rgba(0, 0, 0, .1), 0 18px 23px rgba(0, 0, 0, .1);
                    @media (min-width: $breakpoint-small) {
                        font-size: 1rem;
                    }
                    @media (min-width: $breakpoint-medium) {
                        font-size: .9em;
                    }
                    .break {
                        display: block;
                        font-size: 1em;
                        font-weight: bold;
                        margin-left: 0;
                    }
                }
                &:hover {
                    figure {
                        &:before {
                            background: rgba($global-color, $alpha: .5);
                        }
                    }
                }
            }
            &:last-child {
                div {
                    &:first-child {
                        margin-bottom: 30px;
                    }
                    figure {
                        padding: 0;
                        margin: 0;
                        height: 245px;
                        @media (min-width: $breakpoint-small) {
                            height: 110px;
                        }
                        @media (min-width: $breakpoint-medium) {
                            height: 245px;
                        }
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        figure {
            padding: 0;
            margin: 0;
            height: 245px;
            position: relative;
            transition: all .2s ease-in-out;
            @media (min-width: $breakpoint-small) {
                height: 250px;
            }
            @media (min-width: $breakpoint-medium) {
                height: 520px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: rgba($global-color, $alpha: 0);
                transition: all .2s ease-in-out;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.section-background {
    background: $global-color-green-01;
    position: relative;
    .uk-container {
        padding: $padding-padding*2 0;
    }
    .container-h2 {
        h2 {
            color: white;
        }
        span {
            color: white;
        }
    }
    .container-items-accessoires {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        @media (min-width: $breakpoint-small) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: $breakpoint-medium) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    &.section-background-green-01 {
        background: $global-color-green-02;
    }
    &.section-background-white {
        background: white;
    }
    .container-h2 {
        text-align: center;
    }
}

.trace {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100px;
    overflow: hidden;
    z-index: 0;
    opacity: .05;
    padding: 0;
    margin: 0;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.container-h2 {
    text-align: center;
}

.section-soldes {
    padding: 5px;
    @media (min-width: $breakpoint-small) {
        padding: $padding-padding*2 0;
    }
    .uk-container {
        max-width: 100%;
        @media (min-width: $breakpoint-small) {
            max-width: 1200px;
        }
        @media (min-width: $breakpoint-large) {
            max-width: 1380px;
        }
    }
    figure {
        margin: 0;
        padding: 0;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

#container_button_doc{
    flex-direction: row;
    justify-content: center;
}