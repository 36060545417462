//
// Component: Container
//
// ========================================================================
// Variables
// ========================================================================
.uk-container {
    padding: 0;
    max-width: 90%;
    margin: 0 auto;
    @media (min-width: $breakpoint-medium-light) {
        max-width: 1200px;
    }
    @media (min-width: $breakpoint-large) {
        max-width: 1300px;
    }
}