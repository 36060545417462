//
// Component: Espace Compte
//
// ========================================================================
.section-espace-client {
    .container-facette-espace-compte {
        margin: $margin-margin 0 $margin-margin*2 0;
    }
}

.container-facette-espace-compte {
    margin: $margin-margin 0 $margin-margin*2 0;
    @media (min-width: $breakpoint-medium-light) {
        margin: 0 0 $margin-margin 0;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        @media (min-width: $breakpoint-medium-light) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;
        }
        li {
            a {
                display: block;
                background: $global-color-light-gray;
                color: $global-color-green-03;
                display: flex;
                flex-flow: column;
                height: 120px;
                justify-content: center;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1;
                @media (min-width: $breakpoint-medium-light) {
                    font-size: 1em;
                    height: 180px;
                }
                span {
                    font-size: 2.5em;
                }
                &.active,
                &:hover {
                    text-decoration: none;
                    background: $global-color-green-02;
                    color: white;
                }
            }
        }
    }
}

.container-nav-facette {
    margin-right: 30px;
    .container-facette-espace-compte {
        margin: $margin-margin 0 $margin-margin*2 0;
        @media (min-width: $breakpoint-medium-light) {
            margin: 0 0 $margin-margin 0;
        }
        ul {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5px;
            @media (min-width: $breakpoint-medium) {
                grid-template-columns: repeat(1, 1fr);
            }
            li {
                a {
                    height: 80px;
                    font-size: .9em;
                }
            }
        }
    }
}