//
// Component: Base
//
// ========================================================================
// Variables
// ========================================================================
// Base
// ========================================================================
html {
    font-weight: 100;
    margin-top: 0!important;
    background: white;
}

body {
    line-height: 1.8;
    background: white;
    font-family: $global-font-family;
}

a {
    transition: all .2s ease-in-out;
}

.main {
    position: relative;
    z-index: 1000;
    width: 100%;
}

h1 {}

h2 {
    color: $global-color-green-01;
    font-family: $global-font-family;
    font-weight: bold;
    text-align: center;
    margin: 0;
    font-size: 1.8rem;
}

h3 {
    font-style: italic;
}

.container-h2,
.container-h1 {
    color: $global-color-green-01;
    margin: 20px 0;
    span {
        font-size: 2.5em;
        color: $global-color-green-02;
    }
}