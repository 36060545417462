//
// Component: Footer
//
// Footer
// ========================================================================
footer {
    position: relative;
    z-index: 100;
    //margin-top: $margin-margin*3;
    background: $global-color-dark-gray;
    padding: $padding-padding*2 0 $padding-padding 0;
    color: white;
    .koredge-link {
        display: flex;
        justify-content: center;
        color: white;
        margin-top: $margin-margin;
        span {
            font-size: 2em;
            font-weight: 300;
        }
    }
    .uk-container {
        display: block;
        @media (min-width: $breakpoint-medium) {
            display: flex;
            justify-content: space-between;
        }
        .logo {
            margin-right: $margin-margin*2;
            margin-bottom: $margin-margin*2;
            @media (min-width: $breakpoint-small) {
                margin-bottom: 0;
            }
            .logo-footer {
                text-transform: uppercase;
                color: white;
                font-weight: 500;
                font-size: 2em;
                line-height: 1;
                display: block;
                padding-bottom: 20px;
                border-bottom: 1px solid white;
                margin-bottom: $margin-margin;
                &:hover {
                    text-decoration: none;
                }
                span {
                    font-style: italic;
                    font-size: .5em;
                    display: block;
                    font-weight: bold;
                }
            }
        }
        .container-infos {
            display: block;
            @media (min-width: $breakpoint-small) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: $margin-margin;
            }
            @media (min-width: $breakpoint-medium) {
                margin-top: 0;
            }
            .infos {
                margin-bottom: $margin-margin*2;
                @media (min-width: $breakpoint-medium) {
                    margin-bottom: 0;
                }
                .item {
                    margin-bottom: $margin-margin;
                    font-style: italic;
                    display: flex;
                    align-items: flex-start;
                    span {
                        font-size: 1.8em;
                    }
                    p {
                        padding: 0;
                        margin: 0;
                        span {
                            margin-right: 5px;
                        }
                    }
                    a {
                        color: white;
                        font-weight: 500;
                    }
                }
                .title {
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                nav {
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        li {
                            a {
                                color: white;
                                font-size: .9em;
                                font-style: italic;
                            }
                        }
                    }
                }
            }
        }
    }
}