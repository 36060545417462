//
// Component: Panier
//
// Panier
// ========================================================================
section {
    &.heading-title-cart {
        .uk-container {
            display: flex;
            align-items: center;
            border-bottom: 2px solid $global-color-gray-soft;
            h1 {
                &:after {
                    content: none;
                }
            }
            .container-number-products {
                margin: 0 0 0 auto;
                span {
                    color: $global-color-green-01;
                }
            }
        }
    }
}

section {
    &.section-my-cart {
        padding-bottom: $padding-padding*2.5;
        .uk-container {
            .button-panier {
                display: flex;
                align-items: center;
                margin: 0 0 0 auto;
                border: 1px solid transparent;
                font-size: 1em;
                &::after {
                    content: "\e93c";
                    font-family: 'icomoon';
                    font-size: 1.3em;
                }
                &:hover {
                    border: 1px solid $global-color-green-01;
                    background: $global-color-white;
                    color: $global-color-green-01;
                }
            }
            .uk-subnav-pill {
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 $margin-margin/2 0;
                @media (min-width: $breakpoint-small) {
                    margin: 0 0 $margin-large-margin-l 0;
                }
                @media (min-width: $breakpoint-medium-light) {
                    grid-template-columns: repeat(2, 1fr);
                }
                &:before {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: #f8f8f8;
                    position: absolute;
                    top: 35px;
                }
                li {
                    padding: 0;
                    margin-bottom: $margin-margin/2;
                    @media (min-width: $breakpoint-small) {
                        width: 25%;
                        margin-bottom: 0;
                    }
                    &:last-child {
                        a {
                            &:after {
                                content: none;
                            }
                            &:hover {
                                &:after {
                                    content: none;
                                }
                            }
                        }
                    }
                    &.item-subnav-gray {
                        a {
                            text-align: center;
                            display: flex;
                            align-items: center;
                            flex-flow: column;
                            color: $global-color;
                            text-transform: uppercase;
                            font-weight: 600;
                            font-size: 1em;
                            letter-spacing: 1px;
                            &:hover {
                                background: none;
                            }
                            span {
                                font-size: 3em;
                                font-weight: 500;
                                border: 1px solid $global-color-gray-soft;
                                border-radius: 50%;
                                padding: 5px;
                                margin-bottom: 10px;
                                background: white;
                                color: $global-color-gray-soft;
                            }
                        }
                    }
                    &.uk-active {
                        &:last-child {
                            a {
                                &:after {
                                    content: none;
                                }
                                &:hover {
                                    &:after {
                                        content: none;
                                    }
                                }
                            }
                        }
                        a {
                            background: none;
                            color: $global-color;
                            text-transform: uppercase;
                            span {
                                background: $global-color-green-02;
                                color: $global-color-white;
                                border: 1px solid $global-color-green-02;
                            }
                        }
                    }
                    &.checked {
                        a {
                            span {
                                background: $global-color-gray-soft;
                                color: white;
                            }
                        }
                    }
                }
            }
            .uk-switcher {
                h3 {
                    color: $global-color-green-01;
                    border-bottom: 1px solid $global-color-gray-soft;
                }
            }
            #adresses {
                .mes-adresses {
                    .adresse {
                        background: $global-color-green-02;
                        position: relative;
                        cursor: pointer;
                        h2 {
                            text-align: left;
                            color: white;
                            border-bottom: none;
                            margin: 0;
                        }
                        input {
                            display: none;
                        }
                        label {
                            color: white;
                        }
                        &.active {
                            border: 5px solid $global-color-green-01;
                        }
                    }
                }
                .btn-submit.uk-margin {
                    margin: 40px auto!important;
                    background: $global-color-green-02;
                    &:hover {
                        background: $global-color-orange;
                    }
                }
                .message-commande {
                    padding: $padding-padding;
                    background: #f8f8f8;
                    label {
                        color: $global-color;
                        display: block;
                        margin-bottom: 10px;
                    }
                    textarea {
                        display: block;
                        background: white;
                        width: 100%;
                    }
                }
            }
            #summary {
                .table-responsive {
                    text-align: center;
                }
                .item {
                    font-family: $global-font-family;
                    font-weight: bold;
                    font-size: 1.2em;
                    color: #707073;
                    cursor: inherit;
                    &:before {
                        content: "";
                        display: inline-block;
                        font-family: 'icomoon';
                        font-size: 1.2em;
                        color: $global-color-dark-gray;
                        vertical-align: middle;
                        margin: 0 $margin-margin 0 0;
                    }
                    &.item-picto-1 {
                        &:before {
                            content: "\e915";
                        }
                    }
                    &.item-picto-2 {
                        &:before {
                            content: "\e90b";
                        }
                    }
                    &.item-picto-3 {
                        &:before {
                            content: "\e91d";
                        }
                    }
                    &.item-picto-4 {
                        &:before {
                            content: "\e929";
                        }
                    }
                    .uk-radio {
                        margin-left: $margin-margin;
                        &:checked {
                            background: $global-color-green-01;
                        }
                    }
                }
            }
            .table-responsive {
                position: relative;
                .table-responsive-full {
                    margin-top: 0;
                }
                #my-cart-table {
                    td {
                        padding: $padding-padding/4 $padding-padding/2;
                        display: block;
                        text-align: left;
                        float: left;
                        width: 50%;
                        @media (min-width: $breakpoint-small) {
                            display: table-cell;
                            text-align: center;
                            float: none;
                            width: inherit;
                        }
                        &.trash {
                            button {
                                border: none;
                                background: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                margin: 0 auto;
                                cursor: pointer;
                                span {
                                    font-size: 3em;
                                    color: $global-color-green-02;
                                    font-weight: 500;
                                }
                                &:hover {
                                    span {
                                        color: $global-color-orange;
                                    }
                                }
                            }
                        }
                    }
                    tbody {
                        .uk-width-small {
                            width: 40%;
                            @media (min-width: $breakpoint-small) {
                                width: 12%;
                            }
                        }
                        .product img {
                            width: 100%;
                            @media (min-width: $breakpoint-small) {
                                width: 100%;
                            }
                        }
                        .quantity {
                            width: 40%;
                            @media (min-width: $breakpoint-small) {
                                width: auto;
                            }
                        }
                        .description {
                            text-align: left;
                            font-size: 1em;
                            p {
                                padding: 0;
                                margin: 0;
                                &.title {
                                    a {
                                        color: $global-color-green-01;
                                        font-weight: 500;
                                        font-size: 1em;
                                    }
                                }
                                &.ref {
                                    font-size: 0.8em;
                                    font-style: italic;
                                    color: $global-color-dark-gray;
                                }
                            }
                        }
                        .unit-price-container {
                            p {
                                &.unit-price {
                                    color: $global-color-orange;
                                    font-family: $global-font-family;
                                    text-align: left;
                                    margin: 0;
                                    font-size: 1em;
                                    @media (min-width: $breakpoint-medium-light) {
                                        text-align: center;
                                    }
                                    @media (min-width: $breakpoint-large) {
                                        font-size: 1.2em;
                                    }
                                    @media (min-width: $breakpoint-xlarge) {
                                        font-size: 1em;
                                    }
                                }
                                &.old-price {
                                    color: $global-color-gray-soft;
                                    font-size: 0.6em;
                                    text-align: left;
                                    margin: 0;
                                    text-decoration: line-through;
                                    @media (min-width: $breakpoint-medium-light) {
                                        text-align: center;
                                        font-size: 1em;
                                    }
                                }
                            }
                        }
                        .delivery {
                            font-size: 1em;
                            p {
                                font-size: .8em;
                            }
                        }
                        .total {
                            color: $global-color-orange;
                            font-weight: 500;
                            font-family: $global-font-family;
                            font-size: 1em;
                        }
                        .discount {
                            color: $global-color-dark-gray;
                            font-weight: bold;
                            font-size: 1em;
                            p {
                                background: $global-color-green-02;
                                font-size: 1em;
                                padding: 5px;
                                width: 50%;
                                margin: 0 auto;
                                color: $global-color-white;
                                font-family: $global-font-family;
                                @media (min-width: $breakpoint-small) {
                                    width: auto;
                                }
                            }
                        }
                    }
                    tbody.totaux {
                        position: relative;
                        @media (min-width: $breakpoint-small) {
                            position: absolute;
                            right: 0;
                            width: 35%;
                        }
                        tr {
                            border-bottom: none;
                            margin-bottom: 0;
                            @media (min-width: $breakpoint-small) {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 0;
                            }
                            &:nth-of-type(odd) {
                                background: white;
                            }
                            th {
                                padding: 0;
                                display: none;
                                text-align: right;
                                font-size: 1.2em;
                                text-transform: none;
                                color: $global-color-dark-gray;
                                @media (min-width: $breakpoint-small) {
                                    display: block;
                                }
                                &.total {
                                    font-family: $global-font-family;
                                    text-transform: uppercase;
                                    font-size: 1em;
                                    font-weight: bold;
                                }
                            }
                            td {
                                text-align: right;
                                padding: 20px;
                                font-size: 1.2em;
                                color: $global-color;
                                text-transform: uppercase;
                                font-family: $global-font-family;
                                &:first-child {
                                    width: 70%;
                                }
                                &:last-child {
                                    width: 30%;
                                    background: #e8e8e8;
                                }
                                p {
                                    font-weight: 500;
                                    font-size: 1em;
                                }
                            }
                        }
                    }
                }
            }
            .container-buttons-navigation {
                display: block;
                justify-content: space-between;
                margin-top: $margin-large-margin-l;
                @media (min-width: $breakpoint-small) {
                    display: flex;
                    align-items: center;
                }
                .btn-submit {
                    margin: 20px auto;
                    @media (min-width: $breakpoint-small) {
                        margin: 20px 0;
                    }
                    &.little {
                        height: auto;
                        padding: 5px 20px;
                    }
                    &.next {
                        background: $global-color-green-02;
                        &:hover {
                            background: $global-color-orange;
                        }
                    }
                }
                .uk-button {
                    margin: 0 auto $margin-margin auto;
                    font-size: 1em;
                    @media (min-width: $breakpoint-small) {
                        margin: 0;
                        padding: 0 10px;
                        font-size: .7em;
                    }
                    @media (min-width: $breakpoint-medium-light) {
                        font-size: 1em;
                    }
                }
                .container-right {
                    display: block;
                    align-items: center;
                    justify-content: space-between;
                    @media (min-width: $breakpoint-small) {
                        display: flex;
                        width: 50%;
                    }
                    @media (min-width: $breakpoint-medium-light) {
                        width: 37%;
                    }
                    @media (min-width: $breakpoint-large) {
                        width: 33%;
                    }
                }
                .shopping-button {
                    background: $global-color-green-01;
                    border: 1px solid transparent;
                    &:before {
                        content: "\e90a";
                        font-family: 'icomoon';
                        font-size: 1em;
                        color: $global-color-white;
                        display: block;
                        margin-right: $margin-margin/2;
                        transform: rotate(180deg);
                    }
                    &:hover {
                        background: $global-color-white;
                        color: $global-color-green-01;
                        border: 1px solid $global-color-green-01;
                        &:before {
                            color: $global-color-green-01;
                        }
                    }
                    &:after {
                        content: none;
                    }
                }
                .next-button {
                    border: 1px solid transparent;
                    transition: all .3s ease-in-out;
                    &:after {
                        content: "\e90a";
                        font-family: 'icomoon';
                        font-size: 1em;
                        color: $global-color-white;
                        display: block;
                        margin-left: $margin-margin/2;
                    }
                    &:hover {
                        background: $global-color-white;
                        color: $global-color-green-01;
                        border: 1px solid $global-color-green-01;
                        &:after {
                            color: $global-color-green-01;
                        }
                    }
                }
                .pdf-button {
                    border: 1px solid transparent;
                    transition: all .3s ease-in-out;
                    &:after {
                        content: "\e925";
                        font-family: 'icomoon';
                        font-size: 1em;
                        color: $global-color-white;
                        display: block;
                        margin-left: $margin-margin/2;
                    }
                    &:hover {
                        background: $global-color-white;
                        color: $global-color-green-01;
                        border: 1px solid $global-color-green-01;
                        &:after {
                            color: $global-color-green-01;
                        }
                    }
                }
            }
        }
    }
}

#modal-delete-product {
    text-align: left;
    .uk-modal-dialog {
        border-radius: 5px;
        width: 100%;
        @media (min-width: $breakpoint-medium-light) {
            width: 40%;
        }
        .container-modal-cart {
            display: block;
            align-items: flex-start;
            text-align: center;
            @media (min-width: $breakpoint-medium-light) {
                display: flex;
            }
            .img-product {
                object-fit: contain;
                width: 200px;
                height: 200px;
                text-align: center;
                margin: $margin-margin*1.5 auto;
                @media (min-width: $breakpoint-medium-light) {
                    width: 40%;
                }
            }
            .content-product {
                width: 100%;
                text-align: left;
                @media (min-width: $breakpoint-medium-light) {
                    width: 50%;
                }
                .header-title {
                    border-bottom: 1px solid #EBE9E9;
                    margin-bottom: 10px;
                    h2 {
                        color: $global-color-dark-gray;
                        font-size: 1.8em;
                        margin: 0;
                        line-height: 1.2;
                    }
                    .category {
                        color: #b1b1b1;
                        font-family: $global-font-family;
                        font-size: .8em;
                        text-transform: uppercase;
                        padding: 0;
                        margin: 0;
                    }
                }
                .referency {
                    color: $global-color-dark-gray;
                    font-size: 1em;
                    font-weight: bold;
                    padding: 0;
                    margin: 0;
                }
                .rates {
                    .rate {
                        color: $global-color-green-01;
                        font-size: 2em;
                        margin: 0;
                        font-family: $global-font-family;
                    }
                    .through {
                        color: $global-color-dark-gray;
                        font-size: 1em;
                        margin: 0;
                        text-decoration: line-through;
                        font-family: $global-font-family;
                    }
                    .discount {
                        background: #CA0001;
                        color: $global-color-white;
                        padding: 0 2px;
                        width: max-content;
                        margin: 0;
                        font-size: .8em;
                        font-family: $global-font-family;
                    }
                }
                .container-quantity {
                    display: block;
                    justify-content: space-between;
                    @media (min-width: $breakpoint-small) {
                        display: flex;
                    }
                    .quantity,
                    .total {
                        color: $global-color-dark-gray;
                        font-size: 1.5em;
                        font-weight: bold;
                        margin: $margin-margin/2 0 0 0;
                        @media (min-width: $breakpoint-small) {
                            font-size: 1.5em;
                        }
                        @media (min-width: $breakpoint-medium-light) {
                            font-size: 1em;
                        }
                        span {
                            color: $global-color-green-01;
                            font-family: $global-font-family;
                        }
                    }
                }
            }
        }
        .center {
            text-align: center;
        }
    }
}

.table-responsive {
    display: block;
    width: 100%;
    .container-confirmation {
        padding: $padding-padding;
        background: $global-color-white;
        border: 2px solid $global-color-green-01;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                margin: 0 $margin-margin;
                color: $global-color-green-01;
            }
        }
    }
    .table-responsive-full {
        box-shadow: none;
        width: 100%;
        margin: $margin-margin*1.5 0;
        @media (min-width: $breakpoint-small) {
            font-size: .7em;
        }
        @media (min-width: $breakpoint-medium-light) {
            font-size: 1em;
        }
        thead {
            border-bottom: 3px solid #e5e5e5;
            th {
                font-family: $global-font-family;
                color: $global-color-green-01;
                text-transform: none;
                text-align: center;
                text-transform: uppercase;
                @media (min-width: $breakpoint-small) {
                    font-size: .9em;
                }
                @media (min-width: $breakpoint-large) {
                    font-size: 1em;
                }
                &.uk-width-medium {
                    text-align: left;
                }
            }
        }
        tbody {
            td {
                text-align: center;
                @media (min-width: $breakpoint-medium-light) {
                    font-size: .7em;
                    text-align: center;
                }
                @media (min-width: $breakpoint-large) {
                    font-size: 1em;
                }
                &.uk-width-medium {
                    text-align: left;
                }
                &.uk-width-small {
                    width: 10%;
                }
            }
        }
        thead tr,
        tfoot tr {
            display: none;
            @media (min-width: $breakpoint-small) {
                display: table-row;
            }
        }
        tbody tr {
            margin-bottom: 20px;
            display: block;
            overflow: auto;
            @media (min-width: $breakpoint-small) {
                overflow: inherit;
                display: table-row;
            }
            &.impair {
                background: #f8f8f8;
            }
            &.pair {
                background: white;
            }
        }
        .spinner-form {
            display: flex;
            align-items: center;
            justify-content: center;
            .buttons-more-less {
                background: $form-background;
                border: 1px solid rgba(65, 74, 89, 0.2);
                border-radius: 5px;
                span {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
                    @media (min-width: $breakpoint-xlarge) {
                        width: 20px;
                        height: 20px;
                    }
                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        background: $global-color-green-02;
                        display: block;
                        font-size: 2em;
                        height: 2px;
                        width: 10px;
                        top: 50%;
                        left: 50%;
                        margin-top: -1px;
                        margin-left: -5px;
                    }
                    &.plus {
                        &::after {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            input {
                width: 30px;
                height: 30px;
                text-align: center;
                margin: 0 ($margin-margin/2);
                padding: $padding-padding/4;
                background: $form-background;
                border: 1px solid rgba(65, 74, 89, 0.2);
                border-radius: 5px;
                font-size: 1em;
                font-family: $global-font-family;
                @media (min-width: $breakpoint-xlarge) {
                    width: 50px;
                    height: 30px;
                }
            }
        }
        tr,
        td {
            display: block;
            @media (min-width: $breakpoint-small) {
                display: table-row;
            }
        }
        td {
            border-top: none;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $global-color-dark-gray;
            @media (min-width: $breakpoint-small) {
                display: table-cell;
            }
            &:first-child:before {
                width: 100%;
            }
            &::before {
                content: attr(data-label);
                display: inline-block;
                font-size: .8em;
                font-weight: normal;
                margin-left: 0;
                text-align: left;
                text-transform: uppercase;
                width: auto;
                color: $global-color-dark-gray;
                white-space: nowrap;
                @media (min-width: $breakpoint-small) {
                    display: none;
                    font-size: 1em;
                }
            }
            &.address {
                display: block;
                @media (min-width: $breakpoint-small) {
                    display: table-cell;
                }
                &::before {
                    width: 100%;
                }
            }
            &.details {
                a {
                    &::before {
                        content: "\e91a";
                        font-family: 'icomoon';
                        font-size: 2em;
                        color: $global-color-dark-gray;
                        display: block;
                        margin: 0 auto;
                        transform: scale(1);
                        transition: all .1s ease-in-out;
                    }
                    &:hover {
                        text-decoration: none;
                        &::before {
                            transform: scale(1.2);
                        }
                    }
                }
            }
            &.contact {
                a {
                    &::before {
                        content: "\e92f";
                        font-family: 'icomoon';
                        font-size: 2em;
                        color: $global-color-dark-gray;
                        display: block;
                        margin: 0 auto;
                        transform: scale(1);
                        transition: all .1s ease-in-out;
                    }
                    &:hover {
                        text-decoration: none;
                        &::before {
                            transform: scale(1.2);
                        }
                    }
                }
            }
            &.statut {
                span {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    border-radius: 20px;
                    text-align: center;
                    margin: 0;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    @media (min-width: $breakpoint-small) {
                        margin: 0 auto;
                    }
                }
                &.statut-ok {
                    span {
                        &::before {
                            content: "";
                            width: 30px;
                            height: 30px;
                            display: block;
                            background: url("../images/svg/validate.svg") no-repeat center center;
                        }
                    }
                }
                &.statut-no {
                    span {
                        &::before {
                            content: "";
                            width: 30px;
                            height: 30px;
                            display: block;
                            background: url("../images/svg/error-order.svg") no-repeat center center;
                        }
                    }
                }
                &.statut-in-progress {
                    span {
                        width: 43px;
                        &::before {
                            content: "";
                            width: 43px;
                            height: 10px;
                            display: block;
                            background: url("../images/svg/in-progress.svg") no-repeat center center;
                        }
                    }
                }
            }
            img {
                border-radius: 5px;
                width: 100%;
                max-height: 100px;
                object-fit: contain;
            }
        }
        &.uk-table-hover>tr:hover,
        &.uk-table-hover tbody tr:hover {
            background: #e1e1e1;
        }
    }
}

.container-promo-totaux {
    display: block;
    @media (min-width: $breakpoint-small) {
        display: flex;
        justify-content: space-between;
    }
    .promo {
        .continuer-achats {
            margin: $margin-margin auto;
            @media (min-width: $breakpoint-small) {
                margin: 0;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        form {
            label {
                color: $global-color-green-01;
                font-weight: 500;
                font-size: 1.2em;
                margin-bottom: 10px;
            }
        }
    }
    .container-totaux {
        .container-price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $global-color-gray-soft;
            &:last-child {
                border-bottom: none;
            }
            .label {
                width: 70%;
                text-transform: uppercase;
                font-weight: bold;
            }
            .price {
                width: 30%;
                font-weight: bold;
                background: #f8f8f8;
                &.total-price {
                    color: $global-color-orange;
                }
            }
            >div {
                text-align: right;
                padding: 20px;
            }
        }
    }
    .totaux {
        position: relative;
        tr {
            border-bottom: none;
            margin-bottom: 0;
            height: 80px;
            @media (min-width: $breakpoint-small) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0;
            }
            &:nth-of-type(odd) {
                background: white;
            }
            th {
                padding: 0;
                display: none;
                text-align: right;
                font-size: 1.2em;
                text-transform: none;
                color: $global-color-dark-gray;
                @media (min-width: $breakpoint-small) {
                    display: block;
                }
                &.total {
                    font-family: $global-font-family;
                    text-transform: uppercase;
                    font-size: 1em;
                    font-weight: bold;
                }
            }
            td {
                text-align: right;
                padding: 20px;
                font-size: 1.2em;
                color: $global-color;
                text-transform: uppercase;
                font-family: $global-font-family;
                &:first-child {
                    width: 70%;
                }
                &:last-child {
                    width: 30%;
                    background: #e8e8e8;
                }
                p {
                    font-weight: 500;
                    font-size: 1em;
                }
            }
        }
    }
}

.container-paiement {
    margin-bottom: $margin-margin*4;
    .choix-paiement {
        display: flex;
        align-items: center;
        padding: 20px;
        background: #f8f8f8;
        position: relative;
        cursor: pointer;
        margin: 20px 0;
        transition: all .2s ease-in-out;
        @media (min-width: $breakpoint-small) {
            margin: 20px 60px;
        }
        &:hover {
            background: #eaeaea;
        }
        div {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            span {
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: 2em;
                color: $global-color-orange;
                @media (min-width: $breakpoint-small) {
                    right: 20px;
                    bottom: inherit;
                }
            }
        }
        input {
            display: none;
        }
        label {
            display: block;
            width: 100%;
            cursor: pointer;
            @media (min-width: $breakpoint-small) {
                display: flex;
                align-items: center;
            }
            figure {
                padding: 0;
                margin: 0;
            }
        }
    }
}

.section-header {
    &.section-validate-page {
        margin: $margin-margin*4 0;
        .container-h1 {
            h1 {
                color: #089c25;
                &:before {
                    content: "";
                    background: url('../images/svg/validate.svg') no-repeat center center;
                    width: 60px;
                    height: 60px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
    &.section-error-page {
        margin: $margin-margin*4 0;
        .container-h1 {
            h1 {
                color: #fa4b4a;
                &:before {
                    content: "";
                    background: url('../images/svg/error-order.svg') no-repeat center center;
                    width: 60px;
                    height: 60px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}