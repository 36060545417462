//
// Component: Listing
//
// Listing
// ========================================================================
.section-listing {
    position: relative;
    .uk-container {
        z-index: 1;
        position: relative;
    }
    .container-filtre-listing {
        display: block;
        @media (min-width: $breakpoint-small) {
            display: flex;
        }
        .filtres {
            width: 100%;
            @media (min-width: $breakpoint-small) {
                width: 30%;
            }
            .title-filtre {
                font-weight: 500;
                font-size: 2em;
                margin: 0;
                padding: 0;
                height: 60px;
            }
            .container-filtres {
                border: 1px solid #dedede;
                background: white;
                padding: 20px 20px 40px 20px;
                margin-bottom: 20px;
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
                .uk-accordion-title {
                    color: $global-color-green-01;
                }
                .checkbox {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 5px;
                    label {
                        color: $global-color;
                        letter-spacing: 1px;
                        font-size: .9rem;
                        .uk-checkbox {
                            background: $global-color-white;
                            border: 1px solid $global-color-green-02;
                            width: 15px;
                            height: 15px;
                            margin-right: $margin-margin/2;
                            &:checked {
                                background: $global-color-green-02;
                            }
                        }
                    }
                }
            }
        }
        .listing {
            width: 100%;
            @media (min-width: $breakpoint-small) {
                margin-left: 2%;
                width: 69%;
            }
            .formulaire-de-tri,
            .formulaire-de-tri-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 180px;
                flex-flow: column;
                margin: 20px 0;
                @media (min-width: $breakpoint-medium-light) {
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-around;
                    height: 100px;
                }
                .container-select-pertinence {
                    .uk-select {
                        width: 150px;
                    }
                }
                .container-nb-page {
                    label {
                        margin-right: 10px;
                    }
                }
            }
            .formulaire-de-tri-bottom {
                position: absolute;
                right: 0;
                @media (min-width: $breakpoint-small) {
                    bottom: 0;
                }
            }
            .container-nb-page {
                display: flex;
                align-items: center;
            }
            .list-items-card {
                margin: 0;
                padding: 0;
                list-style: none;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 20px;
                @media (min-width: $breakpoint-small) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
    .trace {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 100px;
        overflow: hidden;
        z-index: 0;
        opacity: .05;
        padding: 0;
        margin: 0;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}


/**** Custom styles for noUiSlider ****/

.noUi-target {
    background: $global-color-dark-gray;
}

.range-slider-filter {
    .noUi-horizontal {
        height: 3px;
        width: 95%;
        margin: 0 auto;
        .noUi-connect {
            background: $global-color-gray-soft;
        }
    }
    .slider-kilometres-value,
    .slider-prix-value,
    .slider-mensualite-value,
    .slider-annee-value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .km {
            color: $global-color-gray-soft;
            font-size: .8rem;
            font-style: italic;
        }
    }
}

.noUi-marker.noUi-marker-horizontal.noUi-marker-normal {
    top: 12px;
}

div .noUi-horizontal .noUi-handle {
    top: -10px;
    width: 22px;
    height: 22px;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
    background: $global-color-green-02;
    border-radius: 50%;
    border: none;
}

.noUi-target.noUi-horizontal .noUi-tooltip {
    background-color: $global-color-orange;
}

.slider-budget-desktop,
.slider-budget-mobile,
.slider-mensualites-desktop,
.slider-mensualites-mobile {
    margin: $margin-margin*1.5 0 $margin-margin*2 0;
    background: #d6d9de;
}