//
// Component: Header
//
// Header
// ========================================================================
header {
    margin: 0;
    padding: 0;
    background: white;
    .container-top {
        display: block;
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 90%;
            margin: 0 auto;
        }
        @media (min-width: $breakpoint-medium-light) {
            max-width: 1200px;
        }
        @media (min-width: $breakpoint-large) {
            max-width: 1300px;
        }
        .container-left {
            display: block;
            @media (min-width: $breakpoint-small) {
                display: flex;
                align-items: center;
            }
            .container-logo {
                text-align: center;
                @media (min-width: $breakpoint-small) {
                    text-align: left;
                }
            }
            .container-phone {
                display: none;
                @media (min-width: $breakpoint-small) {
                    display: flex;
                    align-items: center;
                    margin-left: 40px;
                }
                span {
                    font-size: 2em;
                }
                p {
                    margin: 0;
                    padding: 0;
                    font-weight: 500;
                }
                a {
                    color: $global-color-gray-soft;
                    font-style: italic;
                }
            }
        }
        .container-right {
            display: none;
            @media (min-width: $breakpoint-small) {
                display: flex;
                align-items: center;
            }
            .links {
                background: transparent;
                color: $global-color;
                display: block;
                font-size: 1em;
                text-align: center;
                height: 120px;
                width: 100px;
                font-weight: 600;
                font-style: italic;
                border: none;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                z-index: 10;
                margin-left: $margin-margin;
                transition: all .2s ease-in-out;
                span {
                    font-size: 2em;
                }
                &:hover {
                    background: $global-color-green-03;
                    color: white;
                    text-decoration: none;
                }
                &.panier {
                    background: $global-color-green-03;
                    color: white;
                    span {
                        font-size: 2.8em;
                    }
                    &:hover {
                        background: $global-color-orange;
                        color: white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.mobile {
    header {
        //display: none;
    }
}