//
// Component: Form
//
//
// ========================================================================
// Form
// ========================================================================
.uk-input,
.uk-select {
    //height: 35px!important;
}

.input-datepicker {
    position: relative;
    span {
        position: absolute;
        right: 0;
        font-size: 2em;
        color: $global-color-green-02;
        font-weight: bold;
    }
}

#ui-datepicker-div {
    display: none;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    .ui-datepicker-calendar thead th {
        padding: 0.25rem 0;
        text-align: center;
        font-size: 0.75rem;
        font-weight: 400;
        color: $global-color;
    }
    .ui-datepicker-calendar tbody td {
        width: 2.5rem;
        text-align: center;
        padding: 0;
    }
    .ui-datepicker-calendar tbody td a {
        display: block;
        border-radius: 0.25rem;
        line-height: 2rem;
        transition: 0.3s all;
        color: #546E7A;
        font-size: 0.875rem;
        text-decoration: none;
        border-bottom: none;
    }
    .ui-datepicker-calendar tbody td a:hover {
        background-color: $global-color-green-02;
        color: $global-color-white;
    }
    .ui-datepicker-calendar tbody td a.ui-state-active {
        background-color: $global-color-green-02;
        color: $global-color-white;
    }
    .ui-datepicker-header a.ui-corner-all {
        cursor: pointer;
        position: absolute;
        top: 0;
        width: 2rem;
        height: 2rem;
        margin: 0.5rem;
        border-radius: 0.25rem;
        transition: 0.3s all;
        border-bottom: none;
    }
    .ui-datepicker-header a.ui-corner-all:hover {
        background-color: #ECEFF1;
    }
    .ui-datepicker-header a.ui-datepicker-prev {
        left: 0;
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
        background-repeat: no-repeat;
        background-size: 0.5rem;
        background-position: 50%;
        transform: rotate(180deg);
    }
    .ui-datepicker-header a.ui-datepicker-next {
        right: 0;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: 50%;
    }
    .ui-datepicker-header a>span {
        display: none;
    }
    .ui-datepicker-title {
        text-align: center;
        line-height: 2rem;
        margin-bottom: 0.25rem;
        font-size: 0.875rem;
        font-weight: 500;
        padding-bottom: 0.25rem;
        color: $global-color-green-02;
        font-weight: bold;
    }
    .ui-datepicker-week-col {
        color: #78909C;
        font-weight: 400;
        font-size: 0.75rem;
    }
}

.btn-reset-password {
    font-style: italic;
    color: $global-color-green-01;
    font-weight: 500;
    font-size: 1.1em;
    text-decoration: underline;
    &:hover {
        color: $global-color-orange;
    }
}

.btn-submit {
    border: none;
    cursor: pointer;
    background: $global-color-green-01;
    color: white;
    font-style: italic;
    width: auto;
    display: table;
    padding: 10px 20px!important;
    margin: $margin-margin auto;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
    min-width: 200px;
    height: 54px;
    transition: all .2s ease-in-out;
    &:hover {
        background: $global-color-orange;
        color: white;
        text-decoration: none;
    }
}

.btn-submit-reverse {
    background: $global-color-orange;
    &:hover {
        background: $global-color-green-01;
        text-decoration: none;
        color: white;
    }
}

.txt-legal {
    color: white;
    margin: $margin-margin 0;
    font-weight: 500;
    a {
        color: $global-color-green-01;
        text-decoration: underline;
        &:hover {
            color: $global-color-orange;
        }
    }
}

.container-checkbox {
    display: flex;
    align-items: center;
    label {
        margin-right: $margin-margin/2;
    }
}

.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
    background-color: $global-color-green-02;
    border-color: transparent;
}

.uk-form-label {
    display: block;
    text-align: left;
    font-weight: 600;
}

.input-mail-actuel {
    text-align: left;
    .mail-actuel {
        margin: 0;
        padding: 0;
        color: $global-color-green-01;
    }
}

form {
    &#creation-compte-coordonnes {
        .uk-grid {
            margin-left: 0;
        }
    }
    .uk-grid {
        margin-left: 0;
        @media (min-width: $breakpoint-medium-light) {
            margin-left: -30px;
        }
    }
    &.promo-form {
        .uk-form-controls {
            display: flex;
            align-items: center;
            .submit-button {
                background: $global-color-green-02;
                color: white;
                text-align: center;
                border: none;
                height: 40px;
                width: 50px;
                margin-left: 10px;
                font-weight: bold;
                cursor: pointer;
                transition: all .2s ease-in-out;
                &:hover {
                    background: $global-color-orange;
                }
            }
        }
    }
    .uk-input,
    .uk-select,
    .uk-textarea {
        border: 1px solid $global-color-green-02;
        background: white;
    }
}