//
// Component: Grid
//
//
// ========================================================================
// Grid
// ========================================================================
.uk-grid>.uk-grid-margin,
*+.uk-grid-margin {
    margin-top: 15px;
}