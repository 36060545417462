//
// Component: Button
//
// ========================================================================
.uk-button-default {
    background: $global-color-green-02;
    color: white;
    font-style: italic;
    border: none;
    padding: 10px;
    line-height: 1;
    transition: all .2s ease-in-out;
    span {
        margin-left: 5px;
        vertical-align: middle;
        font-size: .9em;
    }
    &:hover {
        text-decoration: none;
        background: $global-color-orange;
        color: white;
    }
}

#button_doc{
    position: relative;
    margin-top: 2em;
}