//
// Component: Pagination
//
// ========================================================================
// Variables
// ========================================================================
// Pagination
// ========================================================================
.uk-pagination {
    margin-top: $margin-margin*2;
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        a {
            border: 1px solid $global-color-dark-gray;
            background: $global-color-white;
            color: $global-color-dark-gray;
            width: 30px;
            height: 30px;
            text-align: center;
            border-radius: 50%;
            font-size: .8em;
            line-height: 2em;
            transition: all .3s ease-in-out;
        }
        &:hover {
            a {
                border: 1px solid $global-color-green-02;
                background: $global-color-green-02;
                color: $global-color-white;
            }
        }
        &.uk-active {
            a {
                border: 1px solid $global-color-green-02;
                background: $global-color-green-02;
                color: $global-color-white;
            }
        }
        &.uk-disabled {
            span {
                color: $global-color-dark-gray;
            }
        }
        &.uk-first-column {
            a {
                border: none;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    content: "\e900";
                    font-family: 'icomoon';
                    display: block;
                    font-size: 1.5em;
                    transform: rotate(180deg);
                }
                &:hover {
                    &:before {
                        color: $global-color-orange;
                    }
                }
            }
        }
        &.next {
            a {
                border: none;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    content: "\e900";
                    font-family: 'icomoon';
                    display: block;
                    font-size: 1.5em;
                }
                &:hover {
                    &:before {
                        color: $global-color-orange;
                    }
                }
            }
        }
    }
}