//
// Component: Mes adresses
//
// Mes adresses
// ========================================================================
.section-header {
    .container-content {
        h2 {
            background: none;
            display: block;
            text-transform: none;
            color: $global-color-green-02;
            text-align: left;
            font-weight: 500;
            font-size: 1.5em;
            @media (min-width: $breakpoint-small) {
                font-size: 1.8em;
            }
        }
    }
}

.container-items-compte {
    display: block;
    @media (min-width: $breakpoint-medium) {
        display: flex;
    }
}

.mes-adresses {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin: 0 0 $margin-margin*2 0;
    @media (min-width: $breakpoint-small) {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 0 $margin-margin 0;
    }
    .adresse {
        text-align: left;
        background: #F8F8F8;
        padding: $padding-padding;
        h3 {
            color: $global-color-green-01;
            font-style: italic;
        }
    }
}

.container-buttons {
    display: block;
    margin: 20px 0;
    @media (min-width: $breakpoint-small) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .btn-submit {
        margin: $margin-margin 0;
        width: 100%;
        @media (min-width: $breakpoint-small) {
            width: inherit;
            margin: 0 $margin-margin 0 0;
        }
        &.btn-submit-reverse {
            background: $global-color-orange;
            &:hover {
                background: $global-color-green-01;
                text-decoration: none;
                color: white;
            }
        }
    }
}

.table-historique-commandes {
    border: 0;
    width: 100%;
    @media (min-width: $breakpoint-small) {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }
    thead {
        tr {
            display: none;
            @media (min-width: $breakpoint-small) {
                display: table-row;
            }
        }
    }
    tbody {
        tr {
            margin-bottom: 20px;
            display: block;
            overflow: auto;
            @media (min-width: $breakpoint-small) {
                overflow: inherit;
                display: table-row;
            }
        }
    }
    tr {
        border: 1px solid #ddd;
        padding: .35em;
        //height: 50px;
        td {
            float: left;
            width: 50%;
            display: block;
            text-align: left;
            @media (min-width: $breakpoint-small) {
                float: none;
                width: inherit;
                display: table-cell;
                text-align: center;
            }
            &:before {
                content: attr(data-label);
                display: block;
                font-size: .8em;
                font-weight: normal;
                margin-left: 0;
                text-align: left;
                text-transform: uppercase;
                width: auto;
                color: $global-color-orange;
                white-space: nowrap;
                @media (min-width: $breakpoint-small) {
                    content: none;
                }
            }
            a {
                color: $global-color-green-01;
                &:hover {
                    color: $global-color-orange;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    /* table {
        border: 0;
    }
    table caption {
        font-size: 1.3em;
    }
    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }
    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
        height: auto;
        line-height: 35px;
        padding-left: 10px;
    }
    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
    table td:last-child {
        border-bottom: 0;
    } */
}