//
// Component: Card
//
// ========================================================================
.uk-card {
    background: white;
    padding: 10px;
    position: relative;
    border: 1px solid #dedede;
    a {
        &:hover {
            text-decoration: none;
            figure {
                span {
                    opacity: 1;
                    color: white;
                    z-index: 100;
                }
                &:before {
                    background: rgba($global-color-green-02, $alpha: .5);
                }
            }
        }
        .discount {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            background: $global-color-green-02;
            color: white;
            font-weight: bold;
            z-index: 100;
            padding: 5px 10px;
        }
        figure {
            display: flex;
            justify-content: center;
            padding: 0;
            margin: 0;
            overflow: hidden;
            position: relative;
            min-height: 200px;
            span {
                opacity: 0;
                font-weight: 300;
                font-size: 4em;
                transition: all .2s ease-in-out;
            }
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: rgba($global-color-green-02, $alpha: 0);
                transition: all .2s ease-in-out;
            }
            img {
                object-fit: cover;
                /*width: 100%;
                height: 100%;*/
                max-height: 200px;
            }
        }
        .content {
            margin-top: 10px;
        }
        h3 {
            font-style: italic;
            color: $global-color;
            text-align: left;
            font-size: 1.1em;
            margin: 0 0 10px 0;
        }
        .extrait {
            margin: 0;
            color: $global-color-gray-soft;
            line-height: 1.5;
            font-weight: normal;
            font-size: .8rem;
        }
        .container-prices {
            display: flex;
            align-items: baseline;
            padding: 5px 0;
            p {
                padding: 0;
                margin: 0;
                &.price {
                    color: $global-color-orange;
                    font-weight: bold;
                }
                &.old-price {
                    color: $global-color-gray-soft;
                    font-weight: normal;
                    font-size: .9rem;
                    text-decoration: line-through;
                    margin-left: 10px;
                }
            }
        }
    }
}

.container-btn-accessoires {
    position: relative;
    figure {
        margin: 0;
        padding: 0;
        position: relative;
        height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    h2 {
        z-index: 1;
        color: white;
        text-align: left;
        font-size: 1.2em;
        padding: 0;
        margin: 0 0 0 10px;
        font-weight: normal;
        text-shadow: 0 4px 3px rgba(0, 0, 0, .4), 0 8px 13px rgba(0, 0, 0, .1), 0 18px 23px rgba(0, 0, 0, .1);
        @media (min-width: $breakpoint-small) {
            font-size: 1.5em;
            margin: 0 0 0 40px;
        }
        span {
            display: block;
            font-weight: bold;
        }
    }
    .uk-button-default {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}