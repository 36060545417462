/**
 * Style.css
 *
 */

// 1. Custom variables and variables overwrites.
// @import "variables-theme";
@import "variables";
// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme";
@import "uikit/src/scss/mixins-theme";
@import 'uikit-theme/_import';
// 4. Import UIkit
@import "uikit";
// 5. Custom Files
@import 'custom/_import';
@import 'nouislider/nouislider';
.site-container {
    //max-width: 1600px;
    margin: 0 auto;
}

.section-header {
    margin: 0;
    position: relative;
    .trace {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        overflow: hidden;
        z-index: 0;
        opacity: .05;
        padding: 0;
        margin: 0;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .uk-container {
        text-align: center;
        position: relative;
        z-index: 1;
    }
    .container-h1 {
        h1 {
            color: $global-color-green-01;
            font-family: $global-font-family;
            font-weight: 500;
            font-size: 2rem;
            margin: 0;
            font-weight: bold;
            padding: 0;
            text-align: center;
        }
    }
    p {
        color: $global-color;
    }
    h2 {
        background: $global-color-green-02;
        width: 100%;
        height: 60px;
        font-size: 1.8em;
        text-align: center;
        color: white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
    }
}

.back-button {
    color: $global-color-green-01;
    display: flex;
    align-items: center;
    margin-bottom: 20px!important;
    span {
        display: inline-block;
        transform: rotate(180deg);
    }
    &:hover {
        text-decoration: none;
        color: $global-color-orange;
    }
}

/******  Alerte message   *****/
.alert_message.uk-modal .uk-modal-dialog {
    transform: translateY(-100px); }
.alert_message.uk-modal .uk-modal-dialog .titre {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold; }

.alert_message.uk-modal.uk-open {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px 15px;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: opacity .15s linear; }
.alert_message.uk-modal.uk-open .uk-modal-dialog {
    transform: translateY(100px);
    opacity: 1; }
.alert_message.uk-modal.uk-open.error .uk-modal-dialog {
    border: 5px solid red;
    height: auto; }
.alert_message.uk-modal.uk-open.success .uk-modal-dialog {
    border: 5px solid green;
    height: auto; }
.alert_message.uk-modal.uk-open.warning .uk-modal-dialog {
    border: 5px solid orange;
    height: auto; }

.solde_slide {
    min-height: 500px;
    max-width: 80%;
    margin: 40px auto;
    img {
        width: 100%;
        height: auto;
    }
}