//
// Component: Connexion
//
// ========================================================================
.container-forms {
    max-width: 100%;
    margin: $margin-margin*2 auto!important;
    @media (min-width: $breakpoint-small) {
        display: flex;
    }
    .form {
        margin: 20px 0;
        background: $global-color-green-02;
        padding: $padding-padding;
        text-align: left;
        color: white;
        display: table;
        @media (min-width: $breakpoint-small) {
            margin: 1%;
        }
        sup {
            font-size: 1.2em;
        }
        .warning-message {
            font-size: .8em;
            margin: $margin-margin/2 0;
            line-height: 1.2;
            p {
                color: white;
                span {
                    display: block;
                    text-decoration: underline;
                }
            }
        }
        h2 {
            background: none;
            text-align: left;
            display: block;
            text-transform: none;
            font-weight: bold;
            height: auto;
            margin: 0 0 10px 0;
        }
        p {
            margin: $margin-margin/2 0;
            color: white;
            line-height: 1.4;
        }
        form {
            .uk-form-label {
                color: white;
                font-weight: 500;
            }
        }
    }
}

.section-content {
    .content {
        margin: $margin-margin*3 0 $margin-margin*2 0;
        h2 {
            text-align: left;
        }
    }
    .container-duo,
    .container-trio {
        margin: $margin-margin*2 auto;
        figure {
            width: 100%;
            max-width: 400px;
            height: 400px;
            max-height: 400px;
            img {
                object-fit: cover;
                width: 100%;
                max-width: 100%;
                height: 100%;
            }
        }
    }
    .container-duo {
        .uk-first-column {
            padding-left: 0;
            padding-right: 40px;
        }
    }
}

.uk-grid {
    .uk-first-column {
        padding-left: 0;
    }
}