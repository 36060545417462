//
// Component: Search
//
//
.container-search-form {
    display: none;
    @media (min-width: $breakpoint-small) {
        display: block;
    }
}

.uk-search-default {
    width: 210px;
    display: table;
    @media (min-width: $breakpoint-medium-light) {
        width: 280px;
    }
    @media (min-width: $breakpoint-large) {
        width: 350px;
    }
    .uk-icon {
        color: $global-color-dark-gray;
    }
    .uk-search-input {
        border-radius: 25px;
        background: white;
        color: $global-color-gray-soft;
        font-style: italic;
        font-weight: normal;
        &:focus {
            background: white;
        }
        &::placeholder {
            color: $global-color-gray-soft;
            font-style: italic;
            font-weight: normal;
        }
    }
}